@import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
@import "../node_modules/bootstrap-slider/dist/css/bootstrap-slider.min.css";

/* GENERAL */
body {
    font-weight: 300;
    /* color: #363636; */
    color: #000;
    margin: 0;
    font-family: "Helvetica Neue",Helvetica,Arial,sans-serif;
    font-size: 14px;
    line-height: 1.42857143;
    background-color: #fff;
}

b {
    font-weight: bold;
}
.container {
    background: #fff;
}

.padded-row {
    padding-left: 40px;
    padding-right: 40px;
}

.padded-row-extra {
    /* padding-left: 80px;
    padding-right: 80px; */
}

.padded-top {
    padding-top: 20px;
}

.no-pad-col {
    padding-left: 0;
    padding-right: 0;
}

.no-pad-left-col {
    padding-left: 0;
}

.no-pad-right-col {
    padding-right: 0;
}

.row-same-height {
	display: table;
	width: 100%;
	table-layout: fixed;
}

.col-same-height {
	display: table-cell;
	float: none !important;
}

.text-value {
    color: #1D95B2;
    color: red;
}
.hsaPaymentImage {
    margin-top: 40px;
}

hr.gray {
    border-top: 2px solid #d5d5d5;
    margin: 40px 0;
}

.font-bold {
    font-weight: 500;
}

p.important {
    color: #1D95B2;
    font-size: 22px;
}

p.subtitle {
    font-size: 18px;
}

.mb-0 {
    margin-bottom: 0;
}

/* HEADER */
.page-header {
    border-bottom: none;
}
.page-header img {
    display: inline;
    margin-right: 10px;
}

.header-MainTitle {
    font-size: 32px;
    vertical-align: middle;
    color: red;
    margin-left: 10px;
    margin-top: 30px;
}
.header-title {
    font-size: 18px;
    vertical-align: middle;
    color: #000;

}
.progress-row {
    margin: 30px 0 0 0;
    background: #1D95B2;
}

.progress-row-xs {
    background: #fff;
}

.progress-title {
    color: #fff;
    font-size: 30px;
    line-height: 100px;
    font-weight: normal;  ;
}

.progress-bar-container {
    margin-top: 45px;
}

.progress-bar-container-xs {
    margin-top: 25px;
}

.progress-bar-container-xs .progress-bar {
    background-color: #1D95B2;
}

.progress {
    background-color: #f9f8f6;
    margin-bottom: 2px;
    border-radius: 0;
    height: 15px;
}

.progress-bar {
    background-color: #AAA;
}

.progress-text {
    color: #fff;
    font-size: 11px;
    text-align: right;
}

.progress-text-xs {
    color: #1D95B2;
}

/* LANDING */
.landing-container {
    position: relative;
    margin-top: 120px;
}

.overlay {
    position: absolute;
    top: 30%;
    left: 0;
    background: #1D95B2;
    opacity: 0.9;
    height: 30%;
    width: 45%;
}

.overlay-container {
    display: table;
    height: 100%;
    width: 100%;
}

.overlay-text {
    display: table-cell;
    font-size: 28px;
    color: #fff;
    vertical-align: middle;
    padding: 0 40px 0 80px;
    line-height: normal;
}

.padded-content {
    margin-left: -80px;
    padding-right: 10px;
}

.landing-intro1, .landing-intro2 {
    margin-top: 30px;
}
.landing-intro2 {
    margin-top: 20px;
    padding-bottom: 50px;
    border-bottom: 2px solid #EDEDED;
}

.landing-header {
    color: #1D95B2;
    color: #1D95B2;
    font-weight: bold;
    font-size: 20px;
    vertical-align: middle;
    /* padding-top: 20px; */
    text-align: center;
}

.landing-text {
    margin-top: 20px;
    color: #000;
}

.get-started-container {
    margin: 20px 0;
    text-align: center;
}

.get-started-arrow {
    padding-left: 2px;
    padding-bottom: 2px;
}

/* STEPS */

.input-row {
    margin-top: 40px;
}

.input-row-large {
    margin-top: 60px;
}

.input-label, .label {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 12px;
    color: #6a6c6c;
}

.input-label-blue, .label-blue {
    font-size: 15px;
    color: #1D95B2;
}

.input-label-red, .label-red {
    font-size: 14px;
    color: #1D95B2;
    color: #1D95B2;
    color: #6a6c6c;
}
.vbottom {
    display: inline-block;
    vertical-align: bottom;
    float: none;
}


.coverage-inputs {
    margin-top: 20px;
    margin-bottom: 20px;
}

.coverage-inputs .radio {
    margin-top: 0;
}

.coverage-inputs .radio label {
    padding-left: 0;
    width: 100%;
}

.coverage-image {
    margin-left: 20px;
}

.coverage-box {
    background-color: #1D95B2;
    display: table;
    height: 50px;
    width: 100%;
    padding-left: 20px;
}

.coverage-box .radio-container {
    display: table-cell;
    vertical-align: middle;
    padding-left: 20px;
    color: #fff;
}

.input-error {
    color: red;
    margin-top: 2px;
    font-size: 12px;
}

.usage-container {
    margin-top: 20px;
}
.rpt-intro1 {
    font-weight: bold;
    font-size: 18px;
}
.page-intro {
    color: #7e7b7a;
    margin-bottom: 20px;
    margin-top: 20px;
}

.usage-header {
    background: #1D95B2;
    color: #fff;
    text-align: center;
    padding: 10px;
    cursor: pointer;
}

.usage-header.active {
    background: #919191;
}

.usage-header.active .btn-usage {
    background: #1D95B2;
    color: #fff;
}

.usage-title {
    text-transform: uppercase;
    font-size: 14px;
    /* width: 120px; */
    display:inline-flex;
}

.usage-amount {
    font-size: 26px;
    margin: 10px 0;
}

.usage-amount-xs {
    margin: 0;
    text-align: left;
}

.usage-subtitle {
    font-size: 13px;
    margin-bottom: 20px;
    display: inline;
}

.usage-subtitle-xs {
    margin-bottom: 10px;
    text-align: left;
}
.oopOr {
    font-size: 20px !important;
    font-weight: bold !important;
    text-transform: uppercase;
}
.dropdown-arrow {
    position: absolute;
    right: 20px;
    top: 30px;
    cursor: pointer;
}

.usage-content {
    padding: 5px;
    /* min-height: 100px; */
    /* border-left: 1px solid #d7d7d7;
    border-right: 1px solid #d7d7d7;
    border-bottom: 1px solid #d7d7d7; */
}

.usage-body .usage-content:first-child {
    text-align: center;
}

.usage-icon {
    width: 60px;
    text-align: center;
}

.usage-text {
    font-size: 12px;
    vertical-align: middle;
    padding-left: 10px;
    color: #7e7b7a;
}

.divider {
    position: relative;
    text-align: center;
    margin-top: 50px;
}

.divider span {
    background-color: white;
    padding:          0 15px;
    position:         relative;
    z-index:          1;
    text-transform:   uppercase;
    font-size:        12px;
}

.divider:before {
    content:    '';
    background: #d7d7d7;
    display:    block;
    height:     1px;
    position:   absolute;
    top:        50%;
    width:      100%;
    left: 0;
}
.inputSm {
    max-width: 60px; 
}
.inputMd {
    max-width: 90px; 
}
.inputLg {
    max-width: 120px; 
}
chart {
    display: block;
    width: 100%;
}
.no-paddingLeft {
    padding-left: 0px;

}
.no-paddingRight {

    padding-right: 0px;
}

.summaryContainer {
display: inline-block;
    background: #EDEDED;
    padding: 20px
}

.summaryBox {
    background: #ffffff;
    padding: 20px;
    margin-top: 20px;
}
.highcharts-title {
    width: 100%;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
}

.chart-wrapper {
position: relative;
   padding-bottom: 40%;
}

.chart-inner {
position: absolute;
   width: 100%; height: 100%;
}


.legend-container {
    /* background-color: #f9f7f3; */
    /* min-height: 100px; */
    /* padding: 0px 100px 30px;
    padding: 0px 10px 30px; */
}

.legend-box {
    /* min-height: 80px; */
    background: #fff;
    /* margin-top: 20px; */
    padding: 0 20px;
    cursor: pointer;
}

.legend-box.current {
    border-left: 15px solid #1D95B2;
}

.legend-box.alternate {
    border-left: 15px solid #d5d5d5;
}

.legend-title {
    width: 100%;
    vertical-align: middle;
    color: #252525;
    /* font-size: 16px; */
}

.legend-value {
    width: 40%;
    text-align: right;
    vertical-align: middle;
    /* font-size: 24px; */
    color: #1D95B2;;
    font-weight: 500;
}

.legend-box.current .legend-value {
    color: #1D95B2;
}

.results-header {
    color: #000000;
    color: #1D95B2;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 12px;
}

.results-intro {
    color: #7e7b7a;
    margin-bottom: 10px;
}
.normal {
    font-weight: normal !important;
}
.results-box {
    padding: 20px 30px 0 30px;
    margin-bottom: 20px;
}
.blueBack {
    background: #1D95B2;
}
.results-box.blue {
    background: #1D95B2;
    color: #fff;
}
.blueText {
    color: #1D95B2 !important;
}
.results-box.gray {
    background: #BABABA;
    /* color: #000; */
    color: #FFFFFF;
}

.results-box-text {
    font-size: 14px;
}

.results-box-value {
    font-size: 20px;
    text-align: center;
    margin-top: 15px;
}
.results-box-header {
    font-size: 15px;
    width: 100%;
}
.results-box-valueLeft {

    text-align: left;
   
}

.results-box-bold {
    font-weight: 500;
    margin-bottom: 30px;
}

.results-text {
    color: #7e7b7a;
}

.results-text p {
    margin: 30px 0;
}

.action-item {
    display: table-cell;
    vertical-align: top;
    float: none;
}

.action-item.add-border {
    border-right: 2px solid #d7d7d7;
}

.action-header {
    margin-bottom: 20px;
    min-height: 70px;
}

.action-icon {
    width: 61px;
}
.spenderSaverProfile {
    color: #1D95B2;
}
.action-icon img {
    margin-left: -10px;
}

.action-title {
    padding-left: 15px;
    color: #1D95B2;
    vertical-align: middle;
    line-height: normal;
    font-weight: bold;
    font-size: 18px;
}

.action-body {
    color: #7e7b7a;
    padding-bottom: 10px;
}

.action-body ol {
    padding-left: 1.2em;
}

.action-link {
    cursor: pointer;
}

/* CALLOUTS */
.callout-container {
    background-color: #EDEDED;
    padding: 10px;
}

.callout-icon {
    width: 75px;
    vertical-align: middle;
}

.callout-icon.center {
    text-align: center;
}

.callout-title {
    color: #CE301A;
    font-size: 24px;
    vertical-align: middle;
}

.callout-title-row {
    margin-top: 30px;
}

.callout-title .small, .callout-title.small {
    font-size: 18px;
}

.callout-text {
    color: #646464;
    margin-top: 10px;
}
.callout-container {
    position: relative;
    top: -75px;
    background: white;
    box-shadow:
    0 2.8px 2.2px rgba(0, 0, 0, 0.034),
    0 6.7px 5.3px rgba(0, 0, 0, 0.048),
    0 12.5px 10px rgba(0, 0, 0, 0.06),
    0 22.3px 17.9px rgba(0, 0, 0, 0.072),
    0 41.8px 33.4px rgba(0, 0, 0, 0.086),
    0 100px 80px rgba(0, 0, 0, 0.12)

}
/* 
#step1 .callout-container {
    margin-top: 100px;
}

#step2 .callout-container {
    margin-top: 50px;
}

#step4 .callout-container {
    margin-top: 20px;
}

#summary .callout-container {
    min-height: 638px;
} */

.step-footnote {
    margin-top: 100px;
    font-size: 11px;
    color: #9f917c;
}

.summary-label {
    color: #6a6c6c;
    margin-top: 10px;
    margin-bottom: 4px;
}

.summary-value {
    /* background: #1D95B2; */
    /* background: #1D95B2; */
    color: #fff;
    color: #1D95B2;
    text-align: left;
    font-weight: bold;
    line-height: 20px;
}

.summary-link .tooltip {
    white-space: nowrap;
}

/* FOOTER */
.page-footer {
    /* margin-top: 50px; */
    /* border-top: 6px solid #EDEDED; */
    min-height: 100px;
}

.footer-disclosures {
    margin-top: 20px;
    color: #919191;
    font-size: 12px;
}
.summary-footnote {
    color: #000;
    font-size: 12px;
    margin-top: 60px;
    /*     margin-left: -80px; */
}
.footnotes {
    color: #7e7b7a;
    font-size: 12px;
    margin-top: 20px;
}

.house-icon {
    margin-bottom: 4px;
    margin-left: 2px;
}

.font-white {
    color: #fff;
}

.navigation-row {
    /* margin-top: 10px;
    margin-bottom: 50px; */
}

.nav-btn-container {
    position: relative;
}

.img-prev {
    position: absolute;
    left: 10px;
    top: 12px;
}

.img-next {
    position: absolute;
    right: 0px;
    top: 12px;
}

/* MODALS */
.modal {
    text-align: center;
    padding: 0!important;
}

.modal:before {
    content: '';
    display: inline-block;
    height: 100%;
    vertical-align: middle;
    margin-right: -4px;
}

.modal-dialog {
    display: inline-block;
    text-align: left;
    vertical-align: middle;
    min-width: 600px;
}

.modal-header {
    background: #1D95B2;
    -webkit-border-top-left-radius: 0;
    -webkit-border-top-right-radius: 0;
    -moz-border-radius-topleft: 0;
    -moz-border-radius-topright: 0;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom: none;
    color: #fff;
}

.modal-header .close {
    color: #fff;
    opacity: 0.8;
}

.modal-header .close:hover {
    color: #fff;
}

.modal-content {
    -webkit-border-bottom-left-radius: 0;
    -webkit-border-bottom-right-radius: 0;
    -moz-border-radius-bottomleft: 0;
    -moz-border-radius-bottomright: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.modal-footer {
    border-top: 0;
}
.no-padding, .taxPadding {
    padding-left: 0;
    padding-right: 0;
}
input.D, input.SD, input.P, input.N, input.S {
    height: 34px;
    line-height: 34px;
    border: 2px solid #CCC;
    margin-right: 5px;
}


/* BUTTONS */
.btn {
    border-radius: 0;
    font-weight: 300;
    font-size: 16px;
}
.btn-border {
    border: 1px solid white;
}
.btn:focus, .btn.active:focus {
    outline: none;
}
.btn-blue {
    margin-bottom: 10px;
}

.btn-blue, .btn-getstarted {
    background-color: #1D95B2;
    color: #fff;
}
.btn-getstarted {
    padding: 12px 30px;
    width: 200px;
    height: 50px;
}

.btn-blue:hover {
    color: #fff;
}

.btn-blue.span {
    font-size: 10px;
}

.btn-white {
    background: #fff;
    color: #1D95B2;
    font-weight: 500;
}

.btn-white:hover {
    color: #1D95B2;
}

.btn-white.active {
    background: #7e7b7a;
    color: #fff;
    box-shadow: none;
}

.btn-usage {
    text-transform: uppercase;
    font-size: 14px;
}

.btn-usage:hover, .btn-usage:active, .btn-usage:focus {
    color: #1D95B2;
}

.btn-right-icon {
    padding-left: 40px;
}

/* INPUTS */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
input[type="number"] {
    -moz-appearance: textfield;
}

/** SLIDERS **/

/* bootstrap slider plugin */
.slider.slider-horizontal {
    width: 100%;
    margin-top: 25px;
}

.slider.slider-horizontal .slider-track {
    height: 10px;
    border-radius: 0;
}

.slider-track-low, .slider-track-high, .slider-selection {
    border-radius: 0;
}
.slider-track-low {
    color: red;
}
.slider-selection {
    background: #1D95B2;
}

.slider-track-high {
    background: #1D95B2;
    background: #D5D5D5;
}
app-navigation {
    width: 100%;
}

/* Tony */
.slider-2 .slider-selection {
    background: #D5D5D5;
    background: #1D95B2;
    box-shadow: none;
}

.tooltip-main {
    margin-left: -7px !important;
}

.slider-handle {
    background: #CE301A;
    background: #5b5b5b;
    /* border: 1px solid #1D95B2;
    background-attachment: fixed; */
    height: 22px;
    width: 10px;
    cursor: pointer;
}

.tooltip .arrow {
    opacity: 0;
}

.slider .tooltip-inner {
    background: transparent;
    /* color: #1D95B2; */
    color: #CE301A;
    /* border: 1px solid #1D95B2; */
    border-radius: 0;
}

.slider .tooltip.top .tooltip-arrow {
    /* border-top-color: #1D95B2; */
    border-top-color: transparent;
}

.slider-labels {
    color: #1D95B2;
    color: red;
    margin-top: 8px;
    font-size: 12px;
}

.slider-spend-container {
    width: 100%;
    padding: 0;
    margin: 0;
    display: table;
}

.icon-spender-saver {
    text-decoration: none;
    display: table-cell;
    width: 5%;
    text-align: center;
    vertical-align: top;
    height: 62px;
}

.slider-spend {
    display: table-cell;
    width: auto;
    margin-top: 15px;
    vertical-align: bottom;
    /* padding: 0 15px; */
    padding: 0;;
}

.icon-text {
    font-size: 12px;
    color: #1D95B2;
    text-align: center;
}

/* Webkit */
input[type=range] { -webkit-appearance: none; width: 100%; position: relative; border: 1px solid #dadada; }

input[type=range]::-webkit-slider-runnable-track { width: 100%; border-radius: 0px; height: 24px; background: #1D95B2; }
input[type=range]::-webkit-slider-thumb { -webkit-appearance: none; width: 18px; height: 32px; border-radius: 0px; cursor: pointer; margin-top: -4px; background: #dadada; border: 1px solid #1D95B2;}

input[type="range"]:focus { -moz-box-shadow: 0 0 8px #dadada !important; -webkit-box-shadow: 0 0 8px #dadada !important; box-shadow: 0 0 8px #dadada !important; outline: none !important; }

/* Mozilla */
input[type=range]::-moz-range-track { border-radius: 0px; height: 7px; background-color: #1D95B2; box-shadow: inset 0 2px 2px #dadada; border: none; }
input[type=range]::-moz-range-thumb { width: 18px; height: 18px; border-radius: 10px; cursor: pointer; }

input[type=range]:-moz-focusring { outline: 1px solid #dadada !important; outline-offset: 0; }

/* Internet Explorer */
input[type=range]::-ms-ticks-before,
input[type=range]::-ms-ticks-after,
input[type=range]::-ms-tooltip { display: none; }

input.ie-offset { position: relative; top: -2px; }
input[type=range]::-ms-track { width: 200px; height: 8px; background: transparent; margin-top: 5px; border-color: transparent; border-width: 6px 0; color: transparent; }
input[type=range]::-ms-fill-lower { background: #1D95B2; border-radius: 0px; box-shadow: inset 0 2px 2px #dadada; }
input[type=range]::-ms-fill-upper { background: #1D95B2; border-radius: 0px; box-shadow: inset 0 2px 2px #dadada; }
input[type=range]::-ms-thumb {  border: none; height: 18px; width: 18px; border-radius: 50%; }
input[type=range]:focus::-ms-fill-lower,
input[type=range]:focus::-ms-fill-upper { background: #1D95B2; box-shadow: inset 0 2px 2px #dadada; }

/*==================================================
=            Bootstrap 3 Media Queries             =
==================================================*/

/*==========  Mobile First Method  ==========*/

/* Custom, iPhone Retina */
@media only screen and (min-width : 320px) {

}

/* Extra Small Devices, Phones */
@media only screen and (min-width : 480px) {

}

/* Small Devices, Tablets */
@media only screen and (min-width : 768px) {

}

/* Medium Devices, Desktops */
@media only screen and (min-width : 992px) {

}

/* Large Devices, Wide Screens */
@media only screen and (min-width : 1200px) {

}



/*==========  Non-Mobile First Method  ==========*/

/* Large Devices, Wide Screens */
@media only screen and (max-width : 1199px) {
    .overlay-text {
        font-size: 24px;
        padding: 0 30px 0 40px;
    }
    .padded-content {
        margin-left: -50px;
    }
}

/* Medium Devices, Desktops */
@media only screen and (max-width : 991px) {
    .overlay {
        height: 40%;
    }
    .overlay-text {
        font-size: 22px;
        padding: 0 20px 0 30px;
    }
    .padded-row, .padded-row-extra {
        padding-left: 20px;
        padding-right: 20px;
    }
    .padded-content {
        margin-left: -20px;
    }
    .landing-header {
        /* padding-top: 10px; */
        color: #1D95B2;
        font-weight: bold;
        text-align: center;
    }
    #step1 .callout-container {
        margin-top: 0;
    }
    .action-item {
        display: block;
    }
    .action-item.add-border {
        border-bottom: 2px solid #d7d7d7;
        border-right: none;
    }
}

/* Small Devices, Tablets */
@media only screen and (max-width : 767px) {
    .overlay {
        top: 20%;
        height: 60%;
    }
    .overlay-text {
        font-size: 18px;
        padding: 0 10px 0 20px;
    }
    .callout-container {
        position: relative;
        top: 0px;
        margin-top: 70px;
        margin-bottom: 50px;
    }


}

/* Extra Small Devices, Phones */
@media only screen and (max-width : 480px) {


    .padded-content {
        margin-left: 0;
    }
    .landing-header {
        padding-top: 0;
        color: #1D95B2;
        font-weight: bold;
        text-align: center;
    }
    .page-header {
        padding: 0;
    }
    .header-title {
        font-size: 14px;
    }
    .overlay-text {
        font-size: 16px;
    }
    .slider-spend {
        max-width: 220px;
    }
    .legend-container {
        padding: 15px;
    }
    .results-box {
        padding: 10px;
        text-align: center;
    }
}

/* Custom, iPhone Retina */
@media only screen and (max-width : 320px) {

}

/* Print */
@media print {
    a[href]:after {
        content: '';
    }
    .slider-selection {
        border-top: 18px solid #1D95B2;
        background: transparent;
    }

    .slider-track-high {
        border-top: 18px solid #1D95B2;
        background: transparent;
    }

    .slider-2 .slider-selection {
        border-top: 18px solid #c9c0b6;
        background: transparent;
    }
    .padded-row, .padded-row-extra {
        padding-left: 0px;
        padding-right: 0px;
    }
    .navigation-row {
        display: none;
    }
}